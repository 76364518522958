import type { ApolloError } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { KargoActionLoadingButton } from '@components/kargo-ui/action-loading-button';
import { KargoCameraLocationSelector } from '@components/kargo-ui/camera-location-selector';
import { useFacility } from 'hooks/kargo-ui/use-facility';
import { useToast } from 'hooks/kargo-ui/use-toast';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {
  CameraLocation,
  type KargoDoorLabelLiveLookQuery,
  type KargoDoorLabelLiveLookQueryVariables,
} from 'generated/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

const KARGO_DOOR_LABEL_LIVE_LOOK_QUERY = gql`
  query KargoDoorLabelLiveLookQuery(
    $businessId: Int!
    $facilityId: Int!
    $dockId: Int!
    $liveImageInput: DockLiveImageInput!
  ) {
    business(id: $businessId) {
      id
      facility(id: $facilityId) {
        id
        dock(id: $dockId) {
          id
          liveImage(input: $liveImageInput)
        }
      }
    }
  }
`;

const StyledKargoLiveLookModalContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  column-gap: 8px;
  height: 80vh;
`;

const StyledLoadingContainer = styled.div`
  position: absolute;
  top: 50;
  left: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 0.6;

  :before {
    content: ' ';
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${(p) => p.theme.colors.gray50};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  z-index: 20;
  color: ${(p) => p.theme.colors.gray400};
`;

const StyledKargoLiveLookPhotoContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 80vh;
`;

const StyledKargoLiveImageRefreshButton = styled(KargoActionLoadingButton)`
  margin-top: 16px;
`;

const StyledKargoLiveLookCameraSelectorContainer = styled.div`
  border-left: 1.5px solid ${(p) => p.theme.colors.gray400};
  padding: 0 20px;
`;

const StyledKargoLiveLookDockIdLabel = styled.p`
  margin-bottom: 12px;
  font-weight: 500;
`;

const StyledKargoLiveLookImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

type Props = {
  dockId: number;
};

const KargoDoorLabelLiveLook = ({ dockId }: Props): JSX.Element => {
  const { facility } = useFacility();
  const { showToast } = useToast();
  const refreshButtonIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const anchorTimeRef = useRef<number | null>(null);

  const [, forceUpdate] = useState<{}>({});
  const [selectedCameraView, setSelectedCameraView] = useState<CameraLocation>(
    CameraLocation.TOP_LEFT,
  );
  const [currentLiveImage, setCurrentLiveImage] = useState<string | null>(null);
  const [isRefreshButtonDisabled, setIsRefreshButtonDisabled] =
    useState<boolean>(true);

  const {
    data: liveLookQueryData,
    loading: liveLookQueryLoading,
    error: liveLookQueryError,
    refetch: refetchLiveLookQuery,
  } = useQuery<
    KargoDoorLabelLiveLookQuery,
    KargoDoorLabelLiveLookQueryVariables
  >(KARGO_DOOR_LABEL_LIVE_LOOK_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      businessId: facility.businessId,
      facilityId: facility.id,
      dockId,
      liveImageInput: {
        cameraLocation: selectedCameraView,
        forceRefresh: false,
      },
    },
    onCompleted() {
      handleRefreshButtonEnabling();
    },
  });

  // Clean-up refresh button time interval on unmount
  useEffect(() => {
    return () => {
      if (refreshButtonIntervalRef.current) {
        clearInterval(refreshButtonIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!liveLookQueryLoading && !liveLookQueryError) {
      const liveImageSrc =
        liveLookQueryData?.business.facility.dock.liveImage ?? null;

      setCurrentLiveImage(liveImageSrc);
    }
  }, [liveLookQueryData, liveLookQueryLoading, liveLookQueryError]);

  const handleRefreshButtonEnabling = useCallback(() => {
    if (refreshButtonIntervalRef.current) {
      clearInterval(refreshButtonIntervalRef.current);
    }

    anchorTimeRef.current = Date.now();
    refreshButtonIntervalRef.current = setInterval(() => {
      if (!refreshButtonIntervalRef.current || !anchorTimeRef.current) {
        clearInterval(refreshButtonIntervalRef.current ?? '');

        return;
      }

      const elapsedTime =
        5 - Math.round((Date.now() - anchorTimeRef.current) / 1000);

      if (elapsedTime === 0) {
        clearInterval(refreshButtonIntervalRef.current);

        setIsRefreshButtonDisabled(false);
      }

      forceUpdate({});
    }, 1000);
  }, []);

  const onLiveImageRefresh = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setIsRefreshButtonDisabled(true);

      await refetchLiveLookQuery({
        liveImageInput: {
          cameraLocation: selectedCameraView,
          forceRefresh: true,
        },
      });
    } catch (err) {
      const liveImageRefreshError = err as ApolloError;

      if (
        liveImageRefreshError.graphQLErrors[0].extensions.code ===
        'LIVE_IMAGE_FORCE_REFRESH'
      ) {
        showToast(
          'error',
          'Can only fetch new live tower image every 5 seconds. Please wait and try again.',
        );
      }

      handleRefreshButtonEnabling();
    }
  };

  const onCameraLocationChange = useCallback(
    (cameraLocation: CameraLocation) => {
      setIsRefreshButtonDisabled(true);
      setSelectedCameraView(cameraLocation);
    },
    [],
  );

  const elapsedTime = anchorTimeRef.current
    ? 5 - Math.round((Date.now() - anchorTimeRef.current) / 1000)
    : null;

  return (
    <StyledKargoLiveLookModalContainer>
      <StyledKargoLiveLookPhotoContainer>
        {liveLookQueryError && !currentLiveImage && (
          <StyledKargoLiveLookModalContainer>
            <Typography color='error' margin={1}>
              Unable to load live view of tower. Please try again or contact an
              administrator.
            </Typography>
          </StyledKargoLiveLookModalContainer>
        )}

        {liveLookQueryLoading && (
          <StyledLoadingContainer>
            <StyledCircularProgress />
          </StyledLoadingContainer>
        )}

        {currentLiveImage && (
          <StyledKargoLiveLookImage src={currentLiveImage} alt='Tower image' />
        )}
      </StyledKargoLiveLookPhotoContainer>

      <StyledKargoLiveLookCameraSelectorContainer>
        <StyledKargoLiveLookDockIdLabel>
          Dock Door: {dockId}
        </StyledKargoLiveLookDockIdLabel>

        <KargoCameraLocationSelector
          selectedCameraLocation={selectedCameraView}
          cameraLocationOptions={[
            CameraLocation.TOP_LEFT,
            CameraLocation.TOP_RIGHT,
            CameraLocation.BOTTOM_LEFT,
            CameraLocation.BOTTOM_RIGHT,
          ]}
          onCameraLocationChange={onCameraLocationChange}
        />

        <StyledKargoLiveImageRefreshButton
          loading={liveLookQueryLoading}
          disabled={isRefreshButtonDisabled}
          onClick={onLiveImageRefresh}
        >
          Refresh Image
          {elapsedTime !== null && elapsedTime > 0 && ` ${elapsedTime}`}
        </StyledKargoLiveImageRefreshButton>
      </StyledKargoLiveLookCameraSelectorContainer>
    </StyledKargoLiveLookModalContainer>
  );
};

export { KargoDoorLabelLiveLook };
