import styled from '@emotion/styled';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import LoadingButton from '@mui/lab/LoadingButton';

const KargoActionLoadingButton = styled((props: LoadingButtonProps) => (
  <LoadingButton
    variant='outlined'
    {...props}
    classes={{
      disabled: 'kargo-action-loading-button--disabled',
      ...props.classes,
    }}
  />
))`
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: none;
  border: 2px solid ${(p) => p.theme.colors.black};
  border-radius: 22px;

  :hover {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.black};
    border: 2px solid ${(p) => p.theme.colors.black};
  }

  :focus-visible {
    outline: 2px solid;
  }

  &.kargo-action-loading-button--disabled {
    border-width: 2px;
  }
`;

export { KargoActionLoadingButton };
