import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import Button from '@mui/material/Button';
import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import type { DialogTitleProps } from '@mui/material/DialogTitle';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)`
  float: right;

  :hover,
  :focus-visible {
    background-color: ${(p) => p.theme.colors.primary100};
    opacity: 0.6;
  }
`;

const StyledTitleText = styled.div`
  margin-top: 16px;
  text-align: center;
`;

type StyledDialogActionsProps = {
  isHorizontalActions: boolean;
};

const StyledDialogActions = styled(DialogActions, {
  shouldForwardProp: (prop) => prop !== 'isHorizontalActions',
})<StyledDialogActionsProps>`
  display: flex;
  flex-direction: ${(p) => (p.isHorizontalActions ? 'row-reverse' : 'column')};
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const StyledSaveButton = styled(Button)`
  color: white;
  background-color: ${(p) => p.theme.colors.internationalOrange700};
  border-radius: ${(p) => p.theme.sizing.scale1100};
  text-transform: none;
  transition: opacity 0.2s;
  padding-left: ${(p) => p.theme.sizing.scale800};
  padding-right: ${(p) => p.theme.sizing.scale800};

  :hover,
  :focus-visible {
    background-color: ${(p) => p.theme.colors.internationalOrange700};
    opacity: 0.6;
  }

  :disabled {
    background-color: ${(p) => p.theme.colors.primary100};
  }
`;

const StyledCancelButton = styled(Button)`
  color: ${(p) => p.theme.colors.black};
  border-radius: ${(p) => p.theme.sizing.scale1100};
  text-transform: none;
  transition: opacity 0.2s;
  padding-left: ${(p) => p.theme.sizing.scale800};
  padding-right: ${(p) => p.theme.sizing.scale800};

  :hover,
  :focus-visible {
    background-color: ${(p) => p.theme.colors.primary100};
    opacity: 0.6;
  }
`;

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'buttonClick';

interface Props extends DialogProps {
  DialogTitleProps?: DialogTitleProps;
  cancelText?: string;
  confirmText?: string;
  isConfirmDisabled?: boolean;
  isHorizontalActions?: boolean;
  modalTitle?: string | React.ReactNode;
  modalActions?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: (reason: CloseReason) => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const KargoModal = ({
  DialogTitleProps,
  cancelText,
  confirmText,
  isConfirmDisabled,
  isHorizontalActions = false,
  modalActions,
  modalTitle,
  children,
  onCancel,
  onConfirm,
  onClose,
  ...rest
}: Props): JSX.Element => {
  return (
    <Dialog onClose={onClose} {...rest}>
      <DialogTitle {...DialogTitleProps}>
        {onClose && (
          <StyledIconButton
            aria-label='Close'
            size='small'
            onClick={(e) => {
              e.preventDefault();

              onClose('buttonClick');
            }}
          >
            <Close fontSize='inherit' />
          </StyledIconButton>
        )}

        <StyledTitleText>{modalTitle}</StyledTitleText>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      {(onConfirm || onCancel || modalActions) && (
        <StyledDialogActions
          disableSpacing
          isHorizontalActions={isHorizontalActions}
        >
          {onConfirm && (
            <StyledSaveButton
              autoFocus
              disabled={isConfirmDisabled}
              onClick={onConfirm}
            >
              {confirmText || 'Save'}
            </StyledSaveButton>
          )}

          {onCancel && (
            <StyledCancelButton autoFocus onClick={onCancel}>
              {cancelText || 'Cancel'}
            </StyledCancelButton>
          )}

          {modalActions}
        </StyledDialogActions>
      )}
    </Dialog>
  );
};

export default KargoModal;
